//参与抽奖
const applyLotteryUrl = `/gateway/hc-neighbour/mini/lottery/applyLottery`;
//列表
const getLotteryListForMiniUrl = `/gateway/hc-neighbour/mini/lottery/getLotteryListForMini`;
//详情
const getLotteryDetailForMiniUrl = `/gateway/hc-neighbour/mini/lottery/getLotteryDetailForMini`;
//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
export {
  applyLotteryUrl,
  getLotteryListForMiniUrl,
  getLotteryDetailForMiniUrl,
  userInfoUrl,
};
